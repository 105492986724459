// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-page-js": () => import("/opt/render/project/src/src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-birthday-page-js": () => import("/opt/render/project/src/src/templates/birthday-page.js" /* webpackChunkName: "component---src-templates-birthday-page-js" */),
  "component---src-templates-article-page-js": () => import("/opt/render/project/src/src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-business-page-js": () => import("/opt/render/project/src/src/templates/business-page.js" /* webpackChunkName: "component---src-templates-business-page-js" */),
  "component---src-templates-contact-page-js": () => import("/opt/render/project/src/src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-faq-page-js": () => import("/opt/render/project/src/src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-other-services-page-js": () => import("/opt/render/project/src/src/templates/other-services-page.js" /* webpackChunkName: "component---src-templates-other-services-page-js" */),
  "component---src-templates-quests-page-js": () => import("/opt/render/project/src/src/templates/quests-page.js" /* webpackChunkName: "component---src-templates-quests-page-js" */),
  "component---src-templates-onequest-page-js": () => import("/opt/render/project/src/src/templates/onequest-page.js" /* webpackChunkName: "component---src-templates-onequest-page-js" */),
  "component---src-templates-pricing-page-js": () => import("/opt/render/project/src/src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-tags-js": () => import("/opt/render/project/src/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/opt/render/project/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("/opt/render/project/src/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-tags-index-js": () => import("/opt/render/project/src/src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

